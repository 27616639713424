<template>
<docs-layout>
  <div class="space-y-4 text-left w-full">
    <p>Upload component directly hooks to file service upload URL.</p>
    <p>Ensure you are logged in to test this since the endpoint requires authorization</p>
    <asom-upload v-model="files" />
    <p>List of uploaded files: {{files}}</p>
    <p>States</p>
    <asom-upload state='error' />
  </div>
</docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      files: []
    }
  }
}
</script>